import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { withAuthenticator } from "aws-amplify-react";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import Header from "components/auth/Header";
import InfoDeleteConfirm from "components/auth/InfoDeleteConfirm";
import { getInfos, getInfo, deleteInfo } from "actions/infoAction";

class InfoDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      isModalShow: false
    }
  }

  // モーダルを表示
  handleModalShow = () => {
    this.setState({isModalShow: true})
  };

  // モーダルを閉じる
  handleModalHide = () => {
    this.setState({isModalShow: false})
  }

  // 削除を実行
  handleInfoDelete = () => {
    return this.props.deleteInfo(this.info.info_id, this.props.authData.signInUserSession.idToken.jwtToken)
    // 成功時はreducer側で次画面に遷移する
    .catch(err => {
      consoleLogger('InfoDetail-handleInfoDelete-catch', err);
      throw new Error(err);
    })
  }

  componentDidMount() {
    if(this.props.infoState.infos.length === 0) {
      // リロード対策　stateがないときはお知らせ一覧をサーバーに請求する
      this.props.getInfos(this.props.authData.signInUserSession.idToken.jwtToken)
      .then(res => {
        // contentの取得
        if(typeof this.info !== 'undefined' && typeof this.info.content === 'undefined') {
          this.props.getInfo(this.info.info_id, this.props.authData.signInUserSession.idToken.jwtToken)
          .catch(err => {
            consoleLogger('InfoDetail-getInfo1-catch', err);
            alert(`お知らせの取得に失敗しました\n${err}`);
          })
        }
      })
      .catch(err => {
        consoleLogger('InfoDetail-getInfos-catch', err);
        alert(`お知らせ一覧の取得に失敗しました\n${err}`);
        this.props.history.push("/auth")
      })
    }
    else {
      // まだcontentを取得していない場合はお知らせ情報をサーバーから取得する
      if(typeof this.info !== 'undefined' && typeof this.info.content === 'undefined') {
        this.props.getInfo(this.info.info_id, this.props.authData.signInUserSession.idToken.jwtToken)
        .catch(err => {
          consoleLogger('InfoDetail-getInfo2-catch2', err);
          alert(`お知らせ内容の取得に失敗しました\n${err}`);
        })
      }
    }  
  }

  render() {
    // リロード対策　stateがないときは取り直す
    if(this.props.infoState.infos.length === 0) {
      return null;
    }

    const id = Number(this.props.match.params.info_id);
    const { infos } = this.props.infoState;
    this.info = infos.find(element => {
      return(element.info_id === id)
    });
    consoleLogger('InfoDetail-props', this.props);

    return (
      <Container fluid={true}>
        <Header>
          お知らせ詳細
        </Header>
        { typeof this.info === 'undefined' ?
          <Alert variant="danger" align="center">
            <strong>
              該当するお知らせが存在しません
            </strong>
          </Alert>
        :
          <Table striped bordered hover>
            <thead>
              <tr className="d-flex">
                <th className="col-lg-2">項目名</th>
                <th className="col-lg-10">内容</th>
             </tr>
            </thead>
            <tbody>
              <tr className="d-flex">
                <td className="col-lg-2">ID</td><td className="col-lg-10">{this.info.info_id}</td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">タイトル</td><td className="col-lg-10" style={{wordBreak: 'break-all'}}>{this.info.title}</td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">公開日時</td><td className="col-lg-10">{this.info.publish_day}</td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">作成日時</td><td className="col-lg-10">{this.info.created_at}</td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">作成日者</td><td className="col-lg-10">{this.info.created_email}</td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">操作</td><td className="col-lg-10"><button onClick={this.handleModalShow}  className="btn-primary col-lg-2">削除</button>
                {
                  this.state.isModalShow && (
                    <InfoDeleteConfirm onClose={this.handleModalHide} onSubmit={this.handleInfoDelete}>
                      <div className="InfoDeleteConfirmTitle">{this.info.title}</div>
                    </InfoDeleteConfirm>
                )}
                </td>
              </tr>
              <tr className="d-flex">
                <td className="col-lg-2">内容</td><td className="col-lg-10" style={{wordBreak: 'break-all'}}><div dangerouslySetInnerHTML={{ __html: (typeof this.info.content === 'undefined' ? this.info.content : this.info.content.replace(/\r?\n/g, '<br/>'))}} /></td>
              </tr>
            </tbody>
          </Table>
        }
      </Container>
    );
  }
}
InfoDetail = withRouter(InfoDetail);

function mapStateToProps(state) {
  return {
    infoState: state.info
  }
}

export default withAuthenticator(connect(mapStateToProps, {getInfos, getInfo, deleteInfo})(InfoDetail));
