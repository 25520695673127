import React from "react";
import { Route, Switch } from "react-router-dom";

/* user component */
import WithAuthRoutes from "WithAuthRoutes";
import Home from "containers/Home";
import NotFound from "containers/NotFound";

export default () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/auth" component={WithAuthRoutes} />
    <Route component={NotFound} />
  </Switch>
);
