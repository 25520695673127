import React from "react";
import { Link } from "react-router-dom";
import { Container, ListGroup } from "react-bootstrap";

/* user component */
import Header from "components/Header";

const Home = () => {
  return (
    <Container fluid={true}>
      <Header>
        【BB】お知らせ管理
      </Header>
      <ListGroup as="ul">
        <ListGroup.Item as="li"><Link to={`/auth`}>ログイン</Link></ListGroup.Item>
      </ListGroup>
    </Container>
  );
}

export default Home;
