import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { withAuthenticator } from "aws-amplify-react";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import Header from "components/auth/Header";
import InfoList from 'containers/auth/InfoList';
import { getInfos } from 'actions/infoAction';

class InfoHome extends Component {
  componentDidMount() {
    // お知らせ一覧をサーバーに請求する
    if(this.props.infoState.infos.length === 0 || this.props.infoState.needListReload === true) {
      this.props.getInfos(this.props.authData.signInUserSession.idToken.jwtToken)
      .catch(err => {
        consoleLogger('InfoHome-getInfos-catch', err);
        alert(`お知らせ一覧の取得に失敗しました\n${err}`);
      })
    }
  }

  render() {
    consoleLogger('InfoHome-props', this.props);
    return (
      <Container fluid={true}>
        <Header>
          お知らせ一覧
        </Header>
        <InfoList />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    infoState: state.info
  }
}

export default withAuthenticator(connect(mapStateToProps, {getInfos})(InfoHome));
