import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

/* user component */
import infoReducer from "reducers/infoReducer";

export default (history) => combineReducers({
  router: connectRouter(history),
  form: reduxFormReducer,
  info: infoReducer
})
