import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

/* user component */
import consoleLogger from "helpers/consoleLogger";

const modalRoot = document.getElementById('modal-root');

class InfoDeleteConfirm extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      actionError: false
    }
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  handleSubmit = () => {
    this.setState({actionError: false})
    this.props.onSubmit()
    // 成功時はreducer側で次画面に遷移する
    .catch(err => {
      consoleLogger('InfoDeleteConfirm-handleSubmit-catch', err);
      this.setState({actionError: `お知らせの削除に失敗しました\n${err}`.replace(/Error: /g, '')})
    })
  }

  render() {
    return ReactDOM.createPortal(
      <Background>
        <Contents>
          {this.props.children}
          <hr/>
          {this.state.actionError &&
            <Alert variant="danger" align="center">
              <strong>
                {this.state.actionError}
              </strong>
            </Alert>
          }
          <div align="center">
            <button onClick={this.handleSubmit} className="btn-primary col-6">削除</button>
            <button onClick={this.props.onClose} className="btn-secondary col-6">閉じる</button>
          </div>
        </Contents>
      </Background>,
      this.el
    );
  }
}

export default InfoDeleteConfirm;

const Background = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Contents = styled.div`
  background: #fff;
  width: 40%;
  /* height: 50%; */
  position: fixed;
  top: 30%;
  left: 30%;
  /* transform: translate3d(-50%, -50%, 0); */
  padding: 20px;s
`;
