export const GET_INFOS_REQUEST = 'GET_INFOS_REQUEST'
export const GET_INFOS_SUCCESS = 'GET_INFOS_SUCCESS'
export const GET_INFOS_FAILURE = 'GET_INFOS_FAILURE'
export const POST_INFO_REQUEST = 'POST_INFO_REQUEST'
export const POST_INFO_SUCCESS = 'POST_INFO_SUCCESS'
export const POST_INFO_FAILURE = 'POST_INFO_FAILURE'
export const GET_INFO_REQUEST = 'GET_INFO_REQUEST'
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS'
export const GET_INFO_FAILURE = 'GET_INFO_FAILURE'
export const DELETE_INFO_REQUEST = 'DELETE_INFO_REQUEST'
export const DELETE_INFO_SUCCESS = 'DELETE_INFO_SUCCESS'
export const DELETE_INFO_FAILURE = 'DELETE_INFO_FAILURE'
export const UPDATE_INFO_STATE = 'UPDATE_INFO_STATE'
