const infoValidation = values => {
  const errors = {}

  if (!values.title) {
    errors.title = '必須項目です！'
  } else if (values.title.length > 100) {
    errors.title = '100文字以内で入力してください'
  }

  else if (!values.publish_day) {
    errors.publish_day = '必須項目です！'
  } else if (!values.publish_day.match(/^\d{4}-\d{2}-\d{2} \d{2}$/)) {
    errors.publish_day = 'YYYY-MM-DD HH形式で入力してください'
  }

  else if (!values.content) {
    errors.content = '必須項目です！'
  } else if (values.content > 100000) {
    errors.content = '内容が長すぎます'
  }
  return errors
}

export default infoValidation;
