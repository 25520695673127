import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import Paginator from "components/auth/Paginator";

class InfoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
    this.itemsCountPerPage = 50
  }

  // ページ切り替え
  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber});
  }

  // １行分を描画する
  renderRow(info) {
    return (
      <tr key={info.info_id} className="d-flex">
        <td className="col-lg-1">{info.info_id}</td>
        <td className="col-lg-3" style={{wordBreak: 'break-all'}}><Link to={`/auth/detail/${info.info_id}`}>{info.title}</Link></td>
        <td className="col-lg-2">{info.publish_day}</td>
        <td className="col-lg-2">{info.created_at}</td>
        <td className="col-lg-4">{info.created_email}</td>
      </tr>
    );
  }

  render() {
    consoleLogger('InfoList-props', this.props);
    const { infos } = this.props.infoState;
    const infos_index = (this.state.activePage -1) * this.itemsCountPerPage;

    return (
      <div className="InfoList">
        <div className="pagination">
          <Paginator 
            activePage={this.state.activePage}
            itemsCountPerPage={this.itemsCountPerPage}
            totalItemsCount={infos.length}
            onChange={this.handlePageChange}
          />
        </div>
        <Table striped bordered hover>
          <thead>
            <tr className="d-flex">
              <th className="col-lg-1">ID</th>
              <th className="col-lg-3">タイトル</th>
              <th className="col-lg-2">公開日時</th>
              <th className="col-lg-2">作成日時</th>
              <th className="col-lg-4">作成者</th>
            </tr>
          </thead>
          <tbody>
          { infos.slice(infos_index, infos_index + this.itemsCountPerPage).map((info, index) => {
            return (
              this.renderRow(info)
            );
          }) }
          </tbody>
        </Table>
        <div className="pagination">
        <Paginator 
            activePage={this.state.activePage}
            itemsCountPerPage={this.itemsCountPerPage}
            totalItemsCount={infos.length}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    infoState: state.info
  }
}

export default connect(mapStateToProps)(InfoList);
