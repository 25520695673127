import {
  GET_INFOS_REQUEST, GET_INFOS_SUCCESS, GET_INFOS_FAILURE,
  POST_INFO_REQUEST, POST_INFO_SUCCESS, POST_INFO_FAILURE,
  GET_INFO_REQUEST, GET_INFO_SUCCESS, GET_INFO_FAILURE,
  DELETE_INFO_REQUEST, DELETE_INFO_SUCCESS, DELETE_INFO_FAILURE,
  UPDATE_INFO_STATE
} from "constants/ActionTypes";

const initalState = {
  isFetching: false,
  needListReload: false,
  infos: [],
  payload: "",
  error: false
}

const info = (state = initalState, action) => {
  switch (action.type) {
    case GET_INFOS_REQUEST:
      return {
        ...state,
          isFetching: true,
          infos: [],
          error: false, // 前回が失敗しているかもしれないので明示的にfalseを指定する
      }
    case GET_INFOS_SUCCESS:
      return {
        ...state,
          isFetching: false,
          needListReload: false,
          infos: action.payload,
          lastUpdated: action.meta.receivedAt
      }
    case GET_INFOS_FAILURE:
      return {
        ...state,
          isFetching: false,
          payload: action.payload,
          error: action.error
      }
    case POST_INFO_REQUEST:
      return {
        ...state,
          isFetching: true,
          error: false, // 前回が失敗しているかもしれないので明示的にfalseを指定する
      }
    case POST_INFO_SUCCESS:
      return {
        ...state,
          isFetching: false,
          needListReload: true,
      }
    case POST_INFO_FAILURE:
      return {
        ...state,
          isFetching: false,
          payload: action.payload,
          error: action.error
      }
    case GET_INFO_REQUEST:
      return {
        ...state,
          isFetching: true,
          error: false, // 前回が失敗しているかもしれないので明示的にfalseを指定する
    }
    case GET_INFO_SUCCESS:
      return {
        ...state,
          isFetching: false,
          infos: [...state.infos.filter(element => element.info_id !== action.payload.info_id), action.payload],
          lastUpdated: action.meta.receivedAt
      }
    case GET_INFO_FAILURE:
      return {
        ...state,
          isFetching: false,
          payload: action.payload,
          error: action.error
      }
    case DELETE_INFO_REQUEST:
      return {
        ...state,
          isFetching: true,
          error: false, // 前回が失敗しているかもしれないので明示的にfalseを指定する
      }
    case DELETE_INFO_SUCCESS:
      return {
        ...state,
          isFetching: false,
          needListReload: true,
      }
    case DELETE_INFO_FAILURE:
      return {
        ...state,
          isFetching: false,
          payload: action.payload,
          error: action.error
      }
    case UPDATE_INFO_STATE:
      return {
        ...state,
          infos: action.payload,
    }
    default:
      return state
  }
}

export default info;
