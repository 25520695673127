import React from "react";
import { Route, Switch } from "react-router-dom";
import { withAuthenticator, SignIn, RequireNewPassword, ForgotPassword } from "aws-amplify-react";

/* user component */
import InfoHome from "containers/auth/InfoHome";
import InfoDetail from "containers/auth/InfoDetail";
import InfoRegistHandler from "components/auth/InfoRegistHandler";
import NotFound from "containers/NotFound";

const WithAuth = withAuthenticator(
  ({ match }) => (
    <Switch>
      <Route path={match.url} exact component={InfoHome} />
      <Route path={`${match.url}/regist`} component={InfoRegistHandler} />
      <Route path={`${match.url}/detail/:info_id`} component={InfoDetail} />
      <Route component={NotFound} />
    </Switch>
  ),
  false,
  [
    <SignIn />,
    <RequireNewPassword />,
    <ForgotPassword />
  ]
);

const WithAuthRoutes = props => {
  return <WithAuth {...props} />;
};

export default WithAuthRoutes;
