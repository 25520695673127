import React from "react";
import { Navbar } from "react-bootstrap";

/* user component */

const Header = (props) => {
  return (
    <div className="navibar">
      <Navbar bg="primary" variant="dark" expand="false">
        <Navbar.Brand>{props.children}</Navbar.Brand>
      </Navbar>
    </div>
  );
}

export default Header;
