import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";

/* user component */
import consoleLogger from "helpers/consoleLogger";

class Header extends Component {
  SignOut = () => {
    Auth.signOut()
    .then((data) => {
      this.props.history.push("/")
    })
    .catch((err) => {
      consoleLogger('Header-SignOut-catch', err);
      alert("サインアウトに失敗しました");
    })
  }
  
  handleClickMenu(val) {
    if(val === 1){
      this.SignOut();
    }
  }

  render() {
    const eventKeyArr = this.props.match.url.split('/');
    const eventKey = eventKeyArr[1] + (eventKeyArr[2] ? '/' + eventKeyArr[2] : "");

    return (
      <div className="navibar">
        <Navbar bg="primary" variant="dark" expand="false">
          <Navbar.Brand>{this.props.children}</Navbar.Brand>
          <Nav defaultActiveKey={eventKey} className="flex-lg-row">
            <Nav.Item className="col-lg-6">
              <Nav.Link as={Link} to="/auth" eventKey="auth">お知らせ一覧</Nav.Link>
            </Nav.Item>
            <Nav.Item className="col-lg-6">
              <Nav.Link as={Link} to="/auth/regist" eventKey="auth/regist">お知らせ登録</Nav.Link>
            </Nav.Item>
          </Nav>
          <Dropdown alignRight={true}>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {this.props.authData.attributes.email}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.handleClickMenu.bind(this, 1)}>サインアウト</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
        <br/>
      </div>
    )
  }
}

export default withAuthenticator(withRouter(Header));
