import axios from "axios";
import { push } from "connected-react-router";

/* user component */
import client_config from "client-config";
import consoleLogger from "helpers/consoleLogger";
import {
  GET_INFOS_REQUEST, GET_INFOS_SUCCESS, GET_INFOS_FAILURE,
  POST_INFO_REQUEST, POST_INFO_SUCCESS, POST_INFO_FAILURE,
  GET_INFO_REQUEST, GET_INFO_SUCCESS, GET_INFO_FAILURE,
  DELETE_INFO_REQUEST, DELETE_INFO_SUCCESS, DELETE_INFO_FAILURE,
  //UPDATE_INFO_STATE
} from "constants/ActionTypes";

const getInfosRequest = () => {
  return {
    type: GET_INFOS_REQUEST
  }
}

const getInfosSuccess = (infos) => {  
  return {
    type: GET_INFOS_SUCCESS,
    payload: infos,
    meta: {
      receivedAt: Date.now()
    }
  }
}

const getInfosFailure = (err) => {
  return {
    type: GET_INFOS_FAILURE,
    payload: err,
    error: true
  }
}

const postInfoRequest = () => {
  return {
    type: POST_INFO_REQUEST
  }
}

const postInfoSuccess = () => {  
  return {
    type: POST_INFO_SUCCESS
  }
}

const postInfoFailure = (err) => {
  return {
    type: POST_INFO_FAILURE,
    payload: err,
    error: true
  }
}

const getInfoRequest = () => {
  return {
    type: GET_INFO_REQUEST
  }
}

const getInfoSuccess = (info) => {  
  return {
    type: GET_INFO_SUCCESS,
    payload: info,
    meta: {
      receivedAt: Date.now()
    }
  }
}

const getInfoFailure = (err) => {
  return {
    type: GET_INFO_FAILURE,
    payload: err,
    error: true
  }
}

const deleteInfoRequest = () => {
  return {
    type: DELETE_INFO_REQUEST
  }
}

const deleteInfoSuccess = () => {  
  return {
    type: DELETE_INFO_SUCCESS
  }
}

const deleteInfoFailure = (err) => {
  return {
    type: DELETE_INFO_FAILURE,
    payload: err,
    error: true
  }
}

// 最新データをステートに反映する
//function updateInfoState(latest) {
//  return {
//    type: UPDATE_INFO_STATE,
//    payload: latest,
//    meta: {
//      receivedAt: Date.now()
//    }
//  }
//}

// APIエンドポイントを取得する
function getAPIEndPoint() {
  return client_config["APIGatewayEndPoint"];
}

// アイテム一覧取得
export const getInfos = (jwtToken) => {
    return (dispatch) => {
    dispatch(getInfosRequest())
    return axios.get(getAPIEndPoint()+`/auth/serv/info`, {headers: {'Authorization': jwtToken}})
      .then(res => {
        if(res.data.statusCode === 200) {
          dispatch(getInfosSuccess(res.data.body.items))
        } else {
          consoleLogger('InfoAction-getInfos-statusCode', res.data);
          dispatch(getInfosFailure(res.data))
          throw new Error(JSON.stringify(res.data));
        }
      }).catch(err => {
        consoleLogger('InfoAction-getInfos-catch', err);
        dispatch(getInfosFailure(err))
        throw new Error(err);
      })
  }
}

// アイテム送信
export const postInfo = (json, jwtToken) => {
  return (dispatch) => {
    dispatch(postInfoRequest())
    return axios.post(getAPIEndPoint()+`/auth/serv/info`, {body: json}, {headers: {'Authorization': jwtToken}}, {withCredentials:true})
      .then(res => {
      //  dispatch(getInfos(jwtToken))
      //})
      //.then(infos => {
      //  dispatch(updateInfoState(infos))
        if(res.data.statusCode === 200) {
          dispatch(postInfoSuccess())
          dispatch(push('/auth'))
        } else {
          consoleLogger('InfoAction-postInfo-statusCode', res.data);
          dispatch(postInfoFailure(res.data))
          throw new Error(JSON.stringify(res.data));
        }
      })
      .catch(err => {
        consoleLogger('InfoAction-postInfo-catch', err);
        dispatch(postInfoFailure(err))
        throw new Error(err);
      })
  }
}

// アイテム取得
export const getInfo = (info_id, jwtToken) => {
  return (dispatch) => {
    dispatch(getInfoRequest())
    return axios.get(getAPIEndPoint()+`/auth/serv/info/${info_id}`, {headers: {'Authorization': jwtToken}})
      .then(res => {
        if(res.data.statusCode === 200) {
          dispatch(getInfoSuccess(res.data.body.item))
        } else {
          consoleLogger('InfoAction-getInfo-statusCode', res.data);
          dispatch(getInfoFailure(res.data))
          throw new Error(JSON.stringify(res.data));
        }
      }).catch(err => {
        consoleLogger('InfoAction-getInfo-catch', err);
        dispatch(getInfoFailure(err))
        throw new Error(err);
      })
  }
}

// アイテム削除
export const deleteInfo = (info_id, jwtToken) => {
  return (dispatch) => {
    dispatch(deleteInfoRequest())
    return axios.delete(getAPIEndPoint()+`/auth/serv/info/${info_id}`, {headers: {'Authorization': jwtToken}})
      .then(res => {
        if(res.data.statusCode === 200) {
          dispatch(deleteInfoSuccess())
          dispatch(push('/auth'))
        } else {
          consoleLogger('InfoAction-deleteInfo-statusCode', res.data);
          dispatch(deleteInfoFailure(res.data))
          throw new Error(JSON.stringify(res.data));
        }
      })
      .catch(err => {
        consoleLogger('InfoAction-deleteInfo-catch', err);
        dispatch(deleteInfoFailure(err))
        throw new Error(err);
      })
  }
}
