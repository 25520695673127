let _client_config = [];
_client_config["dev"] = {
  'client_id': '1jqs8if7nhcdgfk51e1uukfoul',
  'region': 'ap-northeast-1',
  'AppWebDomain': 'contents-manage-dev.auth.ap-northeast-1.amazoncognito.com',
  'UserPoolId': 'ap-northeast-1_3sijAqkMl',
  'RedirectUriSignIn': 'http://localhost:8080/hello.html',
  'RedirectUriSignOut': '',
  'APIGatewayEndPoint': 'https://c7dftzf5u5.execute-api.ap-northeast-1.amazonaws.com/dev'
}
const client_config = _client_config[process.env.REACT_APP_PUBLISH_ENV];

export default client_config;
