import React from "react";
import { ConnectedRouter } from "connected-react-router";

/* user component */
import Routes from "Routes";

const App = (props) => {
  return (
    <ConnectedRouter history={props.history}>
      <Routes />
    </ConnectedRouter>
  )
}

export default App;
