import React from "react";
import styled from "styled-components";

export default () => (
  <div className="NotFound">
    <Header>Sorry, page not found!</Header>
  </div>
);

const Header = styled.h3`
  text-align: center;
`;
