import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuthenticator } from "aws-amplify-react";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import InfoRegist from "containers/auth/InfoRegist";
import { postInfo } from "actions/infoAction";

class InfoRegistHandler extends Component {
  saveNewPost = (newInfo) => {
    return this.props.postInfo(newInfo, this.props.authData.signInUserSession.idToken.jwtToken)
      .then(res => {
        consoleLogger('InfoRegistHandler-saveNewPost-then', res);
      })
      .catch(err => {
        consoleLogger('InfoRegistHandler-saveNewPost-catch', err);
        throw new Error(err);
      })
  }

  render() {
    consoleLogger('InfoRegistHandler-props', this.props);
    return (
      <InfoRegist onSubmit={this.saveNewPost} />
    )
  }
}

export default withAuthenticator(connect(null, {postInfo})(InfoRegistHandler));
