import React from "react";
import Pagination from "react-js-pagination";

/* user component */

const Paginator = (props) => {
  return (
    <div className="paginator">
      <Pagination
        activePage={props.activePage}
        itemsCountPerPage={props.itemsCountPerPage}
        totalItemsCount={props.totalItemsCount}
        pageRangeDisplayed={5}
        onChange={props.onChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  )
}

export default Paginator;
