import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, SubmissionError } from "redux-form";
import {
  Container,
  Form,
  FormGroup,
  Col,
  Button,
  Alert
} from "react-bootstrap";
import { withAuthenticator } from "aws-amplify-react";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import Header from "components/auth/Header";
import infoValidation from "helpers/infoValidation";

class InfoRegist extends Component {
  renderField = field => {
    const { input, label, type, cols, placeholder, meta: {touched, error, warning} } = field;
    const validationstate = error ? 'error' : warning ? 'warning' : 'success';
    return (
      <FormGroup as={Col} controlId={input.name} validationstate={touched ? validationstate : null}>
        <Col lg={cols}>
          <Form.Label>{label}</Form.Label>
          <Form.Control as={'input'} {...input} id={input.name} placeholder={placeholder} type={type} isInvalid={touched && error}/>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Col>
      </FormGroup>
    )
  };
  
  renderFieldTA = field => {
    const { input, label, rows, cols, placeholder, meta: {touched, error, warning} } = field;
    const validationstate = error ? 'error' : warning ? 'warning' : 'success';
    return (
      <FormGroup as={Col} controlId={input.name} validationstate={touched ? validationstate : null}>
        <Col lg={cols}>
          <Form.Label>{label}</Form.Label>
          <Form.Control as={'textarea'} {...input} id={input.name} rows={rows} placeholder={placeholder} isInvalid={touched && error}/>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Col>
      </FormGroup>
    )
  };

  handleSubmit = (formData) => {
    return this.props.onSubmit(formData)
      .then(res => {
        consoleLogger('InfoRegist-handleSubmit-then', res);
      })
      .catch(err => {
        consoleLogger('InfoRegist-handleSubmit-catch', err);
        throw new SubmissionError({_error: `お知らせの登録に失敗しました\n${err}`.replace(/Error: /g, '')});
      })
  }

  render() {
    consoleLogger('InfoRegist-props', this.props);
    const { handleSubmit, pristine, reset, submitting, error } = this.props;
    return (
      <Container fluid={true}>
        <Header>
          お知らせ登録
        </Header>
        <Form noValidate onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            name="title"
            component={this.renderField}
            type="text"
            label="タイトル *"
            cols="6"
            placeholder="タイトル"
          />
          <Field
            name="publish_day"
            component={this.renderField}
            type="text"
            label="公開日時 *"
            cols="3"
            placeholder="YYYY-MM-DD HH"
          />
          <Field
            name="content"
            component={this.renderFieldTA}
            rows="5"
            label="お知らせ内容 *"
            cols="12"
            placeholder="入力してください"
          />
          <FormGroup>
            <Col lg={12}>
              {error &&
                <Alert variant="danger" align="center">
                  <strong>
                    {error}
                  </strong>
                </Alert>
              }
              <div align="center">
                <Button type="submit" className="btn-primary col-lg-2" align="center" disabled={pristine || submitting}>登録</Button>
                <Button type="button" className="btn-secondary col-lg-2" align="center" disabled={pristine || submitting} onClick={reset}>クリア</Button>
              </div>
            </Col>
          </FormGroup>
        </Form>
      </Container>
    );
  }
};

const getToday = () => {
  let format = 'YYYY-MM-DD HH';
  let date = new Date();

  format = format.replace(/YYYY/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));

  return format;
}

InfoRegist = reduxForm({
  form: '_InfoRegist',
  validate: infoValidation,
  //destroyOnUnmount: false,
  //forceUnregisterOnUnmount: true,
  initialValues: { publish_day: getToday() },
})(InfoRegist);

const selector = formValueSelector('_InfoRegist');

InfoRegist = connect(state => {
  const contactValue = selector(state, 'contact')
  return {
    contactValue
  }
})(InfoRegist)

export default withAuthenticator(InfoRegist);
